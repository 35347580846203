'use strict';

import Headroom from 'headroom.js'
import Component from "../../base/Component";

export default class Header extends Component {
    constructor(props) {
        super(props)
        this.header = document.querySelector('header');
        this.headroom = null;
    }

    init() {
        this.headroom = new Headroom(this.header, {
            offset: 131
        });
        this.headroom.init();
    }
}