import Component from "../base/Component";
import Alpine from 'alpinejs'

export default class SampleRequestForm extends Component {
  constructor(props) {
    super(props);
    this.sampleRequestData = window.sampleRequestData;
    this.$samplesField = document.querySelector('.fui-type-repeater');
  }

  initRow($row) {
    const $productDropdown = $row.querySelector('[data-product]');
    const $colorDropdown = $row.querySelector('[data-color]');
    this.deleteOptions($productDropdown);
    for (let i = 0; i < this.sampleRequestData.length; i++) {
      const opt = document.createElement('option');
      opt.value = this.sampleRequestData[i].title;
      opt.innerHTML = this.sampleRequestData[i].title;
      $productDropdown.appendChild(opt);
    }

    this.handleProductFieldChanged($row);
    $productDropdown.addEventListener('change', () => {
      this.handleProductFieldChanged($row);
      this.updateRowPreview($row);
    });
    $colorDropdown.addEventListener('change', () => this.updateRowPreview($row));

    this.updateRowPreview($row);
  }

  updateRowPreview($row) {
    const $swatch = $row.querySelector('[data-sample-swatch]');
    const $preview = $row.querySelector('[data-sample-preview]');

    const $productDropdown = $row.querySelector('[data-product]');
    const $colorDropdown = $row.querySelector('[data-color]');

    const selectedProduct = this.sampleRequestData[$productDropdown.selectedIndex];
    const selectedColor = selectedProduct.options[$colorDropdown.selectedIndex];
    $swatch.style.backgroundImage = selectedColor.colorPhoto ? `url("${selectedColor.colorPhoto}")` : null;
    $swatch.style.backgroundColor = selectedColor.colorHex;
    let backgroundImage = `url("${selectedProduct.image}")`;
    if (selectedColor.colorPhoto) {
      backgroundImage = `url("${selectedColor.colorPhoto}"), ${backgroundImage}`
    }
    $preview.style.backgroundImage = backgroundImage
    $preview.style.backgroundColor = selectedColor.colorHex;
  }

  deleteOptions($select) {
    const $oldColorOptions = $select.querySelectorAll('option');
    $oldColorOptions.forEach(o => o.remove());
  }

  handleProductFieldChanged($row) {
    const $productDropdown = $row.querySelector('[data-product]');
    const $colorDropdown = $row.querySelector('[data-color]');
    const selectedIndex = $productDropdown.selectedIndex;
    // Remove existing color options
    this.deleteOptions($colorDropdown)

    // Get new color options
    const product = this.sampleRequestData[selectedIndex];
    const options = product.options

    // Add new color options to dropdown
    for (let i = 0; i < options.length; i++) {
      const opt = document.createElement('option');
      opt.value = options[i].name;
      opt.innerHTML = options[i].name;
      $colorDropdown.appendChild(opt);
    }
  }

  init() {
    this.$samplesField.addEventListener('append', (e) => {
      const $row = e.detail.row;
      this.initRow($row);
    });
    this.$samplesField.addEventListener('init', (e) => {
      let repeaterField = e.detail.repeater;
      if (repeaterField === undefined) {
        return;
      }
      const $rows = repeaterField.getRows();

      $rows.forEach(($row) => {
        this.initRow($row);
      });
    });
  }
}