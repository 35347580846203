import 'lazysizes'
import './static.assets'

import HeadroomJS from './components/ui/Headroom'
import MmenuLight from './components/ui/Mmenu'
import VenveoApplication from "./base/VenveoApplication"
import Headroom from "./components/ui/Headroom";
import SampleRequestForm from './components/SampleRequestForm'

import Alpine from 'alpinejs'
window.Alpine = Alpine

const application = new VenveoApplication();

application.registerComponent('header', 'header', Headroom)
application.registerComponent('mmenu-light', '#mobile-menu-nav', MmenuLight)
application.registerComponent('glightbox', '.glightbox', 'ui/Glightbox')
application.registerComponent('gallery-slider', '#image-gallery-slider', 'ui/sliders/ImageGallerySlider')
application.registerComponent('homepage-hero', '#homepage-hero', 'ui/sliders/HomepageHero')
application.registerComponent('testimonial-slider', '[data-testimonial-slider]', 'ui/sliders/TestimonialSlider')
application.registerComponent('formie-forms', 'form.fui-form', 'FormieForms')
application.registerComponent('sample-request', '#sample-request-form', SampleRequestForm)
application.registerComponent('hero-video', '[data-hero-video]', 'ui/HeroVideo.js');

application.init().then(() => {
    console.log('Site is ready.')
    Alpine.start()
})

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
