'use strict';

import MmenuLight from 'mmenu-light';
import Component from "../../base/Component";

export default class Mmenu extends Component {
    constructor(props) {
        super(props);
    }

    init() {
        const menu = new MmenuLight(
            document.querySelector( '#mobile-menu-nav' ),
            "(max-width: 1023px)"
        );

        const navigator = menu.navigation({
            // options
            // Default options ['light', 'dark']
            theme: 'klauer'
        });

        const drawer = menu.offcanvas({
            // options
            // position: 'right'
        });

        document.querySelector( '[data-mobile-menu-trigger]' )
        .addEventListener( "click", ( event ) => {
            event.preventDefault();
            drawer.open();
        });
        
    }
}